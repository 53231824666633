import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import webby from '../assets/images/webbyAwd.jpg'
import ci1 from '../assets/images/CI-Journeys1.jpg'
import ci2 from '../assets/images/CI-Journeys2.jpg'
import ci3 from '../assets/images/CI-Journeys3.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Children International | Mike Weisert | mikew.design</title>
            <meta name="description" content="Connecting Sponsors and Those in Need to Help End Child Poverty" />
        </Helmet>

        <div id="main" className="alt">
            <section id="banner" className="major ci">
                {/*  
                    <video playsinline autoplay muted loop id="homeBg" width="x" height="y">
                        <source src="static/Chicago.mp4" type="video/mp4" />
                    </video>
                */}
                    <div className="inner">
                    <div className="content">
                        <header className="major">
                            <h1>Children International</h1>
                            <div className="row">
                                <div className="12u 12u$(small)"><a href="https://children.org" target="_blank" rel="noopener noreferrer">Children International</a> is a global organization whose mission is to break the cycle of poverty one child at a time. As a Rightpoint consultant, I worked with Children International to redefine their product offering with an emphasis on digital engagement and lasting donor relationships.<br/>
                               
                                
                            </div>  </div> <div className="empDates">2015</div>
                        </header>
                    </div>
                </div>
            </section>
            <section id="glance">
            <div className="inner">
                    <div className="content">
                    
                        <div className="grid-wrapper">
                        
                            <div className="col-8">
                                <h3>Project At A Glance</h3>
                                <ul>
                                    <li><a href="https://www.webbyawards.com/winners/2018/websites/general/charitable-organizations-non-profit/children-international-redefining-giving-in-a-digital-world/" target="_blank" rel="noopener noreferrer"><b>Webby People’s Voice Award</b></a> for Best Website in the Charitable Organizations/Non-Profit category in the 22nd Annual Webby Awards</li>
                                    <li><a href="http://www.webaward.org/winner/33970/rightpoint-and-childrens-international-redefining-giving-in-a-digital-world--wins-2017-webaward-for-children-international-redefining-giving-in-a-digital-world-.html#.WcVS1EzMyRs" target="_blank" rel="noopener noreferrer"><b>Web Marketing Association Award</b></a> for outstanding achievement in web development</li>
                                    <li>Design of a content platform that increased site visit time to connect sponsors with children in need</li>
                                    <li>Interviewed current CI sponsors and recruited outside audiences to better understand marketplace perception and site needs</li>
                                    <li>Collaborated with the marketing team to create user-experience journeys in the site through the lens of the validated personas established through research</li>
                                    <li>Created guidance and identified opportunities to improve the current online account section and checkout processes</li>
                                </ul>
                            </div>
                        
                            <div className="col-4" id="quick">
                                <h3>About Children International</h3>
                                <ul>
                                    <li>Charitable organization operating for over 80 years</li>
                                    <li>Operating in 10 countries</li>
                                    <li>1M+ kids helped</li>
                                    <li>90% of all funds go directly to programs helping those in need</li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
            </section>
            <section id="three">
                <div className="inner">
                    <div className="content">
                        <h2>The Approach</h2>
                            <p>
                                As the UX consultant for Rightpoint, my role was to help craft an experience for donors and sponsors that better told the story of how their involvement was improving the lives of children around the globe.  We began by conducting user research with existing sponsors, as well as recruited multiple potential sponsors to better understand their impressions and needs from CI.
                            </p>
                            
                            <section>
                                <div className="content">
                                    <div className="inner">
                                        <header className="major">
                                            <h3>Research</h3>
                                        </header>
                                        <p>  Going into the project, the marketing team at CI had created a series of proto-personas based on their experiences.  We used these hypothesis in our research and were able to disprove some incorrect assumptions and identify several opportunities, especially as it related to mobile.
                                We recruited potential sponsors as well as existing sponsors to test these personas.  Based on our outcomes we were able to re-align the personas to a more accurate grouping of 3 user types.
                            </p>    
                            <div className="col-12"><span className="image fit"><img src={ci1} alt="CI Persona Sample" /></span></div>
                                        <ul className="actions">
                                            <li><a href="">Opportunities Presentation</a></li>
                                            <li><a href="">Journeys Discussion</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="content">
                                    <div className="inner">
                                        
                            <div className="calign"><span className="caption">Sponsorship user journey for Devotee persona</span></div>
                            <div className="grid-wrapper">
                               
                                <div className="col-6"><span className="image fit"><img src={ci2} alt="Journey through buying decision" /></span></div>
                                <div className="col-6"><span className="image fit"><img src={ci3} alt="Visual journey" /></span></div>
                            </div>
                                        <ul className="actions">
                                            <li><a href="https://1zfqbn.axshare.com/#p=homepage" target="_blank">Wireframes</a></li>
                                            
                                            <li><a href="https://docs.google.com/presentation/d/130WypF4AHEGZlMEFHXsoye9v1zLfMBTXVyQNsUARQFg/edit#slide=id.p2" target="_blank" rel="noopener noreferrer">Payment Information &amp; Community Engagement (download recommended)</a></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </section>


                            <section>
                                <div className="content">
                                    <div className="inner">
                                        <header className="major">
                                            <h3>Visioning</h3>
                                        </header>
                                        <p>  Going into the project, the marketing team at CI had created a series of proto-personas based on their experiences.  We used these hypothesis in our research and were able to disprove some incorrect assumptions and identify several opportunities, especially as it related to mobile.
                                We recruited potential sponsors as well as existing sponsors to test these personas.  Based on our outcomes we were able to re-align the personas to a more accurate grouping of 3 user types.
                            </p>
                                        <ul className="actions">
                                            <li><a href="https://docs.google.com/presentation/d/1_sy4GWlAAAyM3KEO80md9iSppPeWq9KH6e0AqOuK_2A/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Social Center Visioning (download recommended)</a></li>
                                            
                                            <li><a href="https://docs.google.com/presentation/d/130WypF4AHEGZlMEFHXsoye9v1zLfMBTXVyQNsUARQFg/edit#slide=id.p2" target="_blank" rel="noopener noreferrer">Payment Information &amp; Community Engagement (download recommended)</a></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="content">
                                    <div className="inner">
                                        <header className="major">
                                            <h3>Impact</h3>
                                        </header>
                                        <p> The new CI website allows Children International to better connect with sponsors, tell the stories of children it helps, and represents better represents the organization. Shortly after launch, CI and Rightpoint were recognized with a <a href="https://www.webbyawards.com/winners/2018/websites/general/charitable-organizations-non-profit/children-international-redefining-giving-in-a-digital-world/" target="_blank" rel="noopener noreferrer">Webby for People's Choice, Charitable Organization.</a>
                                        </p>
                                        <div className="col-12"><span className="image fit"><img src={webby} alt="CI Webby Award" /></span></div>
                                    </div>
                                </div>
                            </section>

                    </div>
                </div>
            
            </section>

            <section id="next">
                <div className="inner">
                    <ul className="actions fit">
                        <li><Link to="/fleetmanagement" className="button fit">Back</Link></li>
                        <li><Link to="/professional-services" className="button fit special">Next</Link></li>
                    </ul>
                </div>        
            </section>

        </div>

    </Layout>
)

export default Generic